<template>
  <div class="fluid" id="oeeList">
    <div>
      <div class="oee-list-div">
        <v-card elevation="0" class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h4 class="indigo--text" style="font-weight:bold;margin:auto;">
                OEE MONITORING SYSTEM
              </h4>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="oee-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <div style="width: 120px;">
                    <v-select
                      label="Shift"
                      v-model="paramAPI.schedule_id"
                      :items="[
                        { name: 'Shift 1', id: 1 },
                        { name: 'Shift 2', id: 2 },
                        { name: 'Shift 3', id: 3 }
                      ]"
                      style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      @change="scheduleWatcher"
                    ></v-select>
                  </div>
                  <div style="width: 120px; margin-left:20px;">
                    <v-select
                      label="Machine"
                      v-model="paramAPI.machine_id"
                      :items="dropdown.machine"
                      style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      @change="machineWatcher"
                    ></v-select>
                  </div>
                  <div
                    class="oee-list-toolbar-1"
                    style=" margin-left:20px; margin-right:20px;"
                  >
                    <div>
                      <v-text-field
                        outlined
                        class="attendance-start-date ml-1 mr-2"
                        dense
                        label="Start Date"
                        type="date"
                        name="startdate"
                        style="height:38px;"
                        step="1"
                        v-model="start_date"
                        @change="startDateWatcher"
                        clearable
                      />
                    </div>
                    <div style="margin-right:10px;">
                      <v-text-field
                        style="height:38px;"
                        outlined
                        class="attendance-start-date ml-1 mr-2"
                        dense
                        label="End Date"
                        type="date"
                        name="enddate"
                        step="1"
                        v-model="end_date"
                        @change="endDateWatcher"
                        clearable
                      />
                    </div>
                  </div>
                </v-toolbar>
              </div>
              <v-toolbar class="activity-list-toolbar-2" flat>
                <v-text-field
                  class="attendance-start-date"
                  dense
                  outlined
                  label="Start Date"
                  type="date"
                  name="startdate"
                  style="height:30px;"
                  step="1"
                  v-model="start_date"
                  @change="startDateWatcher"
                  clearable
                />
              </v-toolbar>
              <v-toolbar
                class="activity-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  class="attendance-start-date"
                  dense
                  outlined
                  label="End Date"
                  type="date"
                  name="enddate"
                  style="height:30px;"
                  step="1"
                  v-model="end_date"
                  @change="endDateWatcher"
                  clearable
                />
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="50vh"
                :headers="headers"
                style="cursor: pointer;"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.good_qty`]="{ item }">
                  <div
                    style="font-size:14px; padding: 0; display: flex; flex-direction: row; color:green;"
                  >
                    {{ item.good_qty }}
                  </div>
                </template>
                <template v-slot:[`item.not_good_qty`]="{ item }">
                  <div
                    style="font-size:14px; padding: 0; display: flex; flex-direction: row; color:red;"
                  >
                    {{ item.not_good_qty }}
                  </div>
                </template>
                <template v-slot:[`item.availability`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ Math.round(item.availability * 100).toFixed(2) }}%
                  </div>
                </template>
                <template v-slot:[`item.performance`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ (item.performance * 100).toFixed(2) }}%
                  </div>
                </template>
                <template v-slot:[`item.quality`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ (item.quality * 100).toFixed(2) }}%
                  </div>
                </template>
                <template v-slot:[`item.oee`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ (item.oee * 100).toFixed(2) }}%
                  </div>
                </template>
                <template v-slot:[`item.schedule`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.schedule.shift }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    <v-chip
                      small
                      :color="coloredStatus(item.status)"
                      style="width:60px;"
                      class="d-flex justify-center align-center"
                      text-color="white"
                    >
                      {{ status(item.status) }}
                    </v-chip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters /* mapActions */ } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'oeemonitoring',
  data: () => ({
    dialog: false,
    oee: buildType.apiURL('oee'),
    mms: buildType.apiURL('mms'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'date',
      sortType: 'desc',
      company_id: null,
      department_id: null,
      date: '',
      schedule_id: null,
      machine_id: null
    },
    totalData: 0,

    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'Date',
        value: 'date',
        align: 'left',
        sortable: false
      },
      {
        text: 'Start',
        value: 'start_time',
        align: 'left',
        sortable: false
      },
      {
        text: 'End',
        value: 'end_time',
        align: 'left',
        sortable: false
      },
      {
        text: 'Shift',
        value: 'schedule',
        align: 'left',
        sortable: false
      },
      {
        text: 'Machine',
        value: 'machine_code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Good Qty',
        value: 'good_qty',
        align: 'left',
        sortable: false
      },
      {
        text: 'Bad Qty',
        value: 'not_good_qty',
        align: 'left',
        sortable: false
      },
      {
        text: 'Availability',
        value: 'availability',
        align: 'left',
        sortable: false
      },
      {
        text: 'Performance',
        value: 'performance',
        align: 'left',
        sortable: false
      },
      {
        text: 'Quality',
        value: 'quality',
        align: 'left',
        sortable: false
      },
      {
        text: 'OEE',
        value: 'oee',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    dropdown: {
      machine: [],
      department: []
    },
    start_date: '',
    end_date: '',
    instance: null
  }),

  async mounted() {
    this.instance = axios.create({
      headers: {
        'x-method': 'conventional',
        'x-token':
          'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1pc0BzYW5xdWEuY28uaWQiLCJpZCI6IjEyOCIsImRldmljZSI6Im1vYmlsZSIsInV1aWQiOiJjY2ZiMmI2OS1jYjBjLTQwYzQtOGE1Mi03MjI5NzUzZTQ1M2IiLCJzY29wZSI6ImFjY2Vzc190b2tlbiIsImlhdCI6MTcwNDc4MTQ3NCwiZXhwIjoxNzA1Mzg2Mjc0fQ.YXG5BQM0x7LrugPCdfLHSprZlwUib3XBiGucS3ri0kBVBI9Ua-ebcZd-C_ROZ0kkipnWxjimpafReZNJzrT7Bg5n6a93r62Bujr-UNakXUAZqERh8vK0uMJrkn6OJ1AeJm9hewatlZWLTs1t4-LqxPNczyhserQMRYd4b_uZVIxrVonJXjgudVLGQDxOcYf5iuxhKs-uQBEg3irj92hybZtQS8qBUrChPWjEx2bMYyeiyRZDxzCJOMl0133jWLMcj8voApywLmn4EG_0S4Ei1LCaM2K2G1uKcegJA8MhInexu2CgcfwIb-Lce_F-eRMc69N5ZybqgmnsuYH6sjQIKg',
        'x-device': 'web'
      }
    })

    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    this.paramAPI.department_id = this.getUserProfile.employee.department_id
    this.setOptionDate()
    await this.getDataFromApi()
    await this.initDropdown()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  watch: {},
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    setOptionDate() {
      const d = new Date()
      d.setDate(d.getDate())
      const n = d
        .toLocaleDateString('id')
        .split('/')
        .reverse()
      if (n[1].length === 1 || n[2].length === 1) {
        if (n[1].length === 1) {
          n[1] = `0${n[1]}`
        }
        if (n[2].length === 1) {
          n[2] = `0${n[2]}`
        }
      }
      const joinedDate = n.join('-')
      const sDate = joinedDate
      const sDateSplit = sDate.split('-')
      sDateSplit[2] = '01'
      // this.start_date = sDateSplit.join('-')
      // this.end_date = joinedDate
    },
    machineWatcher() {
      this.getDataFromApi()
    },
    scheduleWatcher() {
      this.getDataFromApi()
    },
    startDateWatcher() {
      if (this.start_date === null) {
        this.start_date = ''
        this.end_date = ''
      }
      this.getDataFromApi()
    },
    endDateWatcher() {
      if (this.end_date === null) {
        this.start_date = ''
        this.end_date = ''
      }
      this.getDataFromApi()
    },
    async initDropdown() {
      this.dropdownMachine()
    },
    searchEnter() {
      this.getDataFromApi()
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then(data => {
        console.log(data)
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      let startDate = ''
      let endDate = ''
      if (this.start_date !== '') {
        startDate = this.start_date + ' 00:00:00'
      }
      if (this.end_date !== '') {
        endDate = this.end_date + ' 23:59:59'
      }
      return await new Promise(resolve => {
        this.instance
          .get(
            `${this.oee}list?keyword=${this.paramAPI.keyword}&offset=${this
              .paramAPI.offset * this.paramAPI.limit}&limit=${
              this.paramAPI.limit
            }&order_by=${this.paramAPI.sortBy}&order_type=${
              this.paramAPI.sortType
            }&start_date=${startDate}&end_date=${endDate}&machine_id=${
              this.paramAPI.machine_id !== null ? this.paramAPI.machine_id : ''
            }&schedule_id=${
              this.paramAPI.schedule_id !== null
                ? this.paramAPI.schedule_id
                : ''
            }`
          )
          .then(res => {
            console.log(res)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      this.$store.commit('setOeeId', pItem.id)
      setTimeout(() => {
        this.$router.push('/mms/oee/detail')
      }, 100)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async dropdownMachine() {
      await this.instance
        .get(`${this.mms}master/universal/machine/dropdown`)
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdown.machine = res.data.data)
          }
          return (this.dropdown.machine = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.machine = [])
        })
    },
    status(val) {
      switch (val) {
        case 0:
          return 'Down'
        case 1:
          return 'Run'
        case 2:
          return 'Break'
      }
    },
    coloredStatus(val) {
      switch (val) {
        case 0:
          return 'red'
        case 1:
          return 'green'
        case 2:
          return 'orange'
      }
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    }
  }
}
</script>
<style lang="scss">
#oeeList {
  position: relative;
  .oee-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .oee-list-toolbar-1 {
      .oee-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #oeeList {
    .oee-list-div {
      .oee-list-toolbar-1 {
        .oee-list-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
